import { Controller } from '@hotwired/stimulus'
import { tabletAndDown } from '@/shared_components/breakPoints'
import { PicViewsWrite } from '@/shared/pic-views/pic-views'

// Connects to data-controller="pics-show"
export default class extends Controller {
  static targets = ['pic', 'picContainer', 'fullscreenButton']
  static values = {
    picViewUrl: String,
    picViewJwt: String
  }

  connect() {
    if (this.picIsLoaded) {
      this.setPicContainerWidth()
    }
    this.addPicView()
  }

  get picIsLoaded() {
    return this.picTarget.complete
  }

  setPicContainerWidth() {
    // this either gets fired from connect() or from the pic element, depending on how long the image takes to load
    this.picContainerTarget.style.width = `${this.picTarget.width}px`
    this.showFullscreenButton()
  }

  showFullscreenButton() {
    if (!tabletAndDown()) {
      this.fullscreenButtonTarget.classList.add('store-pic-fullscreen-button-show')
    }
  }

  openFullscreen() {
    this.picContainerTarget.classList.add('store-pic-fullscreen')
  }

  closeFullscreen() {
    this.picContainerTarget.classList.remove('store-pic-fullscreen')
  }

  toggleFullscreen() {
    if (!tabletAndDown()) {
      this.picContainerTarget.classList.toggle('store-pic-fullscreen')
    }
  }

  addPicView() {
    const picViewsWrite = new PicViewsWrite()
    picViewsWrite.addPicView(this.picViewUrlValue, this.picViewJwtValue)
  }
}
