import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'
import { isMobile, isTablet } from '@/shared_components/breakPoints'

// Connects to data-controller="header"
export default class extends Controller {
  static targets = ['floatingMenu']
  connect() {
    if (!isMobile || !isTablet) {
      // listen for when the header is in the viewport or not
      // we use this to enable/disable floating menu-bar behavior
      useIntersection(this)
    }
  }
  appear() {
    this.disableFloatingMenu()
  }

  disappear() {
    this.enableFloatingMenu()
  }

  enableFloatingMenu() {
    this.floatingMenuTarget.dataset.enabled = 'true'
  }

  disableFloatingMenu() {
    this.floatingMenuTarget.dataset.enabled = 'false'
  }
}
