import StoresV2_MenuController from './menu_controller'

// Connects to data-controller="search"
export default class extends StoresV2_MenuController {
  static targets = ['input']
  toggle() {
    this.element.classList.toggle('store-menu-open')
    this.inputTarget.focus()
  }
}
