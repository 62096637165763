import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="menu"
export default class extends Controller {
  connect() {
    useClickOutside(this, { events: ['click', 'touchmove'] })
  }
  clickOutside(e) {
    if (this.isOpen) {
      e.preventDefault()
      this.close()
    }
  }

  toggle() {
    this.element.classList.toggle('store-menu-open')
  }

  close() {
    this.element.classList.remove('store-menu-open')
  }

  get isOpen() {
    return this.element.classList.contains('store-menu-open')
  }
}
