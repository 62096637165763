// Turbo
import '@hotwired/turbo-rails'
// Stimulus Controllers
import '@/controllers/stores_v2'

// SVG
import { SetupSvg } from '@/shared/utils'

document.addEventListener('turbo:load', function () {
  SetupSvg()
})
