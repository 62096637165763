import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="splash"
export default class extends Controller {
  connect() {
    this.timeout = setTimeout(() => this.close(), 10000) // Auto close after 10 seconds
  }

  close() {
    clearTimeout(this.timeout) // Clear the timeout if close is called manually
    this.element.classList.add('animate-fade-out') // Animate out

    this.element.addEventListener('animationend', () => {
      this.element.remove() // Remove from DOM after animate out is complete
    })
  }
}
