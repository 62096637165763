import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ['form', 'loadingSpinner', 'isNewImages']

  initialize() {
    // set up intersection observer which runs appear()
    useIntersection(this, {
      element: this.loadingSpinnerTarget
    })
  }
  appear() {
    // callback automatically triggered when the element
    // intersects with the viewport
    this.submitForm()
  }

  submitForm() {
    // submit form to fetch new images
    // has to be requestSubmit() for Turbo to intercept
    this.formTarget.requestSubmit()
  }

  requestMoreImages() {
    if (this.hasLoadingSpinnerTarget) {
      this.submitForm()
    }
  }
}
