import BasicModalController from '../../shared/modals/basic_modal_controller'

// Connects to data-controller="basket-modal"
export default class extends BasicModalController {
  static values = {
    isDismissible: Boolean,
    dismissPath: String
  }

  static targets = ['viewBasketButton']

  viewBasketButtonTargetConnected() {
    if (this.viewBasketButtonTarget.dataset.updated === 'true') {
      setTimeout(() => {
        this.viewBasketButtonTarget.dataset.updated = 'false'
      }, '1000')
    }
  }

  addingToBasket(event) {
    event.target.setAttribute('disabled', '')
    event.target.innerHTML = 'Adding to basket'
  }
}
