import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="image-grid"
export default class extends Controller {
  static outlets = ['fullscreen-slider']

  initialize() {
    // Fade in grid images
    setTimeout(() => {
      this.show()
    }, 200)
  }

  show() {
    this.element.style.opacity = 1
  }

  expandThumbnail(event) {
    event.preventDefault()
    this.fullscreenSliderOutlet.open(event.params.thumbnailPicId)
  }
}
